<template>
  <div class="card">
    <h3 class="card-title">Listar áreas</h3>
    <div v-if="areas">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="areas"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showArea"
      ></table-list>
      <modal v-if="openNew" @close="openNew = false">
        <nueva-area slot="modal-body" @saved="refresh"></nueva-area>
      </modal>
    </div>
  </div>
</template>
<script>
import { AreaServices } from "../services/AreaServices";
import TableList from "@/components/elements/TableList";
import NuevaArea from './NuevaArea.vue'
import Modal from '@/components/elements/Modal'
export default {
  name: "ListarAreas",
  components: {
    TableList,
    Modal,
    NuevaArea
  },
  data() {
    return {
      areas: null,
      headers: AreaServices.headers(),
      body: AreaServices.body(),
      actionButton: {
        text: "Nueva área",
        action: () => {
          this.openNew = true;
        },
      },
      openNew: false,
    };
  },
  mounted() {
    AreaServices.api.getAll().then((areas) => (this.areas = areas));
  },
  methods: {
    showArea(area) {
      this.$router.push({ name: "VerArea", params: { id: area.id } });
    },
    refresh() {
      this.openNew = false;
      AreaServices.api.getAll().then((areas) => {
        this.areas = areas;
        this.$refs['tablelist'].refresh(areas)
      });
    },
  },
};
</script>
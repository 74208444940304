<template>
  <div class="modal-form">
    <h3 class="card-title">Cargar area</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Nombre"
        placeholder="Ingrese el nombre del área"
        class="my-1"
        :required="enviado && !$v.descripcion.required"
      ></pd-input>
      <suggest
        id="jefe"
        label="Jefe"
        placeholder="Buscar jefe.."
        class="my-1"
        :data="personas"
        :items="itemsPersona"
        @selected="jefeSelected"
        :required="enviado && !jefe"
      ></suggest>
      <suggest
        id="gerencias"
        label="Gerencias"
        placeholder="Buscar gerencia.."
        class="my-1"
        :data="gerencias"
        :items="itemsGerencia"
        @selected="gerenciaSelected"
        :required="enviado && !gerencia"
      ></suggest>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import Suggest from "@/components/elements/Suggest";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import { GerenciaServices } from '../../gerencias/services/GerenciaServices';
import { AreaServices } from "../services/AreaServices";
import Swal from "sweetalert2";
export default {
  name: "NuevaGerencia",
  data() {
    return {
      personas: null,
      descripcion: "",
      jefe: null,
      gerencia:null,
      gerencias:null,
      itemsGerencia: GerenciaServices.dataList(),
      itemsPersona: PersonasService.dataList(),
      enviado: false
    };
  },
  components: {
    Suggest,
  },
  validations:{
    ...AreaServices.validations
  },
  mounted() {
    PersonasService.api.getAllForSelect().then((personas) => (this.personas = personas));
    GerenciaServices.api.getAll().then((gerencias) => (this.gerencias = gerencias));
  },
  methods: {
    jefeSelected(jefe) {
      this.jefe = jefe;
    },
    gerenciaSelected(gerencia) {
      this.gerencia = gerencia;
    },
    save() {
      this.enviado = true;
      if (!this.descripcion || !this.jefe || !this.gerencia) {
        return false;
      }
      AreaServices.api.save({
        descripcion: this.descripcion,
        jefe_id: this.jefe.id,
        gerencia_id: this.gerencia.id,
      }).then((r) => {
        Swal.fire("Éxito", `<p><b>${r.area.descripcion}</b> se ha guardado con éxito</p>`, 'success');
        this.$emit('saved')
      })
      .catch((e) => {
        Swal.fire("Error", `<p>${e}</p>`, 'error');
      });
    },
  },
};
</script>